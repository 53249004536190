import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { getMaxScroll } from '../lib/helpers';
import Dispatch from '../core/Dispatch';
import {
    ALERT_HIDDEN,
    DOM_CHANGED,
    MENU_CLOSE,
    MENU_OPEN, PROGRAMMATIC_SCROLL_END, PROGRAMMATIC_SCROLL_START
} from '../lib/events';

export default el => {

    const $el = $(el);
    const inner = el.firstElementChild;
    const isTransparent = el.classList.contains('transparent');
    const isFixed = inner.classList.contains('fixed');

    let {
        scrollTop: prevScrollTop,
        width: viewW,
        height: viewH
    } = Viewport;

    let height = $(inner).height();
    let { top: offsetTop } = $el.offset();

    let maxScroll = getMaxScroll();

    let isSticky = isFixed;
    let isHidden = false;
    let menuIsOpen = false;
    let preventShow = false;

    const show = (tween = true) => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        gsap.killTweensOf(inner);
        if (tween) {
            gsap.timeline()
                .to(inner, {
                    yPercent: 0,
                    duration: 0.3
                }, 0);
        } else {
            gsap.set(inner, { yPercent: 0 });
        }
    };

    const hide = (tween = true) => {
        if (isHidden) {
            return;
        }
        isHidden = true;
        gsap.killTweensOf(inner);
        if (tween) {
            gsap.to(inner, {
                yPercent: -100,
                duration: 0.3
            });
        } else {
            gsap.set(inner, { yPercent: -100 });
        }
    };

    const stick = () => {
        if (isSticky || isFixed) {
            return;
        }
        isSticky = true;
        if (isTransparent) {
            el.classList.remove('transparent');
        }
        inner.classList.replace('absolute', 'fixed');
        gsap.set(inner, { top: offsetTop });
    };

    const unstick = () => {
        if (!isSticky || isFixed) {
            return;
        }
        isSticky = false;
        if (isTransparent) {
            el.classList.add('transparent');
        }
        inner.classList.replace('fixed', 'absolute');
        gsap.set(inner, { clearProps: 'top' });
    };

    const onScroll = (tween = true, force = false) => {
        const { scrollY: scrollTop } = window;
        let direction = 'up';
        if (prevScrollTop) {
            direction = scrollTop > prevScrollTop ? 'down' : 'up';
        }
        // Make sticky?
        const top = Math.round(scrollTop);
        if (isSticky) {
            if (top <= 0) {
                unstick();
                show(false);
                return;
            }
            if (menuIsOpen) {
                show(false);
            }
            if (!force && Math.abs(top - prevScrollTop) <= 20) {
                return;
            }
            const threshold = isFixed ? (viewH / 3) : height;
            if (direction === 'down' && top >= threshold) {
                hide(tween);
            } else if (direction === 'up' && scrollTop < maxScroll && !preventShow) {
                show(tween);
            }
        } else if (top >= height) {
            stick();
            hide(false);
            return;
        }
        prevScrollTop = scrollTop;
    };

    const onResize = (updateScroll = true, force = false) => {
        if (!force && Viewport.width === viewW && Math.abs(Viewport.height - viewH) < 150 && getMaxScroll() === maxScroll) {
            return;
        }
        viewW = Viewport.width;
        viewH = Viewport.height;
        height = $(inner).height();
        offsetTop = $el.offset().top;
        maxScroll = getMaxScroll();
        if (updateScroll) {
            onScroll(force);
        }
    };

    let raf;
    const scrollHandler = () => {
        if (raf) {
            cancelAnimationFrame(raf);
        }
        raf = requestAnimationFrame(() => {
            raf = null;
            onScroll();
        });
    };
    window.addEventListener('scroll', scrollHandler);
    Viewport.on('resize', onResize);

    onResize(false, true);
    onScroll(false, true);

    let mutationObserver;
    if (window.MutationObserver) {
        mutationObserver = new MutationObserver(() => {
            onResize();
        });
        mutationObserver.observe(document.body, {
            attributes: false,
            childList: true,
            subtree: true
        });
    }

    Dispatch.on(DOM_CHANGED, onResize);

    const onMenuOpen = () => {
        menuIsOpen = true;
        show(false);
    };

    const onMenuClose = () => {
        setTimeout(() => {
            menuIsOpen = false;
        }, 100);
    };

    const onProgrammaticScrollStart = () => {
        if (!isHidden || !isSticky) {
            return;
        }
        preventShow = true;
    };

    const onProgrammaticScrollEnd = () => {
        preventShow = false;
    };

    const onAlertHidden = () => {
        onResize(true, true);
        if (isSticky) {
            gsap.set(inner, { clearProps: 'top' });
        }
    };

    Dispatch.on(MENU_OPEN, onMenuOpen);
    Dispatch.on(MENU_CLOSE, onMenuClose);
    Dispatch.on(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
    Dispatch.on(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);
    Dispatch.on(ALERT_HIDDEN, onAlertHidden);

    $(el).on('focusin', 'a,button', show);

    return {
        destroy() {
            $(el).off('focusin click');
            window.removeEventListener('scroll', scrollHandler);
            Viewport.off('resize', onResize);
            Dispatch.off(DOM_CHANGED, onResize);
            Dispatch.off(MENU_OPEN, onMenuOpen);
            Dispatch.off(MENU_CLOSE, onMenuClose);
            Dispatch.off(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
            Dispatch.off(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);
            Dispatch.off(ALERT_HIDDEN, onAlertHidden);
            if (mutationObserver) {
                mutationObserver.disconnect();
                mutationObserver = null;
            }
        }
    };

};
