import gsap from 'gsap';
import serialize from 'form-serialize';
import $ from '../core/Dom';
import request from '../core/request';

export default form => {

    const $form = $(form);
    const errorMessage = $form.find('[data-error]')
        .get(0);
    const receipt = $form.find('[data-receipt]')
        .get(0);
    const submitBtn = $form.find('button[type="submit"]')
        .get(0);

    let isSubmitting = false;

    const disableSubmit = () => {
        submitBtn.setAttribute('aria-disabled', 'true');
    };

    const enableSubmit = () => {
        submitBtn.removeAttribute('aria-disabled');
    };

    const hideReceipt = () => {
        // Hide receipt
        gsap.timeline({
            onComplete() {
                enableSubmit();
                receipt.hidden = true;
                const { activeElement } = document;
                if (!activeElement || activeElement === receipt || activeElement.offsetParent === null) {
                    submitBtn.focus();
                }
            }
        })
            .to(receipt, {
                opacity: 0,
                duration: 0.3
            }, 0)
            .to(receipt.children, {
                opacity: 0,
                scale: 1.1,
                duration: 0.3
            }, 0);
        receipt.querySelector('button')
            .removeEventListener('click', hideReceipt);
        form.removeAttribute('tabindex');
        form.removeAttribute('aria-hidden');
    };

    const showReceipt = () => {
        receipt.hidden = false;
        receipt.querySelector('button').focus();
        gsap.timeline({
            onComplete() {
                // Clear input fields
                $form.find('input:not([type="hidden"]),textarea')
                    .get()
                    .forEach(input => {
                        if (['radio', 'checkbox'].indexOf(input.getAttribute('type')) > -1) {
                            input.checked = false;
                        } else {
                            input.value = null;
                            input.classList.remove('has-value');
                        }
                    });
            }
        })
            .fromTo(receipt, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3
            }, 0.15)
            .fromTo(receipt.children, {
                opacity: 0,
                scale: 1.1
            }, {
                opacity: 1,
                scale: 1,
                duration: 0.3
            }, 0.15);
        receipt.querySelector('button')
            .addEventListener('click', hideReceipt);
        form.setAttribute('tabindex', '-1');
        form.setAttribute('aria-hidden', 'true');
    };

    const showGenericError = () => {
        errorMessage.hidden = false;
        errorMessage.focus();
    };

    const clearErrors = () => {
        if (errorMessage) {
            errorMessage.hidden = true;
        }
        $form.find('[data-errors]')
            .each(node => {
                node.textContent = '';
                node.hidden = true;
            });
        $form.find('[data-input].has-errors')
            .removeClass('has-errors');
    };

    const getErrorNames = (errors, prefix = '') => Object.keys(errors || {})
        .reduce((carry, key) => {
            if (typeof errors[key] === 'object' && !Array.isArray(errors[key])) {
                return {
                    ...carry,
                    ...getErrorNames(errors[key], key)
                };
            }
            const name = prefix ? `${prefix}[${key}]` : key;
            return {
                ...carry,
                [name]: errors[key]
            };
        }, {});

    const showErrors = (errors = []) => {

        clearErrors();

        const errorsByName = getErrorNames(errors);
        const errorNames = Object.keys(errorsByName);

        if (!errorNames.length) {
            showGenericError();
            return;
        }

        errorNames.forEach(name => {
            const input = $form.find(`[data-input="${name}"]`)
                .get(0);
            if (input) {
                input.classList.add('has-errors');
            }
            const errorsDiv = $form.find(`[data-errors="${name}"]`)
                .get(0);
            if (errorsDiv) {
                errorsDiv.hidden = false;
                $(errorsDiv)
                    .html([].concat(errorsByName[name])
                        .join('<br/>'));
            }
        });

        const firstErrorInput = $form.find('[data-input].has-errors')
            .find('input:not([type="hidden"]),textarea,select')
            .get(0);
        if (firstErrorInput) {
            firstErrorInput.focus();
        }

        enableSubmit();

    };

    const onSubmit = e => {

        e.preventDefault();

        if (isSubmitting || submitBtn.hasAttribute('aria-disabled')) {
            return;
        }

        clearErrors();

        isSubmitting = true;
        disableSubmit();

        form.classList.add('submitting');

        const data = serialize($form.get(0));
        const url = window.location.href.split('?')[0].split('#')[0];

        request
            .post(url)
            .accept('application/json')
            .send(data)
            .then(({ status }) => {
                if (status !== 200) {
                    throw new Error();
                }
                showReceipt();
                // Track to GTM
                if (window.gtag) {
                    try {
                        const identifier = $form.find('input[type="hidden"][name="identifier"]').val() || null;
                        const referrerEntryTitle = $form.find('input[type="hidden"][name="referrerEntry"]').val() || null;
                        window.gtag('event', 'submit_contact_form', {
                            entry_name: referrerEntryTitle,
                            date: new Date().getTime(),
                            identifier
                        });
                    } catch (error) {
                        console.error(error);
                    }
                }
            })
            .catch(error => {
                console.error(error);
                showErrors(error.response.body.errors || null);
            })
            .then(() => {
                isSubmitting = false;
                form.classList.remove('submitting');
                enableSubmit();
            });
    };

    const init = () => {
        $form.on('submit', onSubmit);
        $form.find('a[href]:not([href^="#"])')
            .each(link => {
                $(link)
                    .attr({
                        target: '_blank',
                        rel: 'noopener noreferrer'
                    });
            });
    };

    const destroy = () => {
        $form.off('submit', onSubmit);
    };

    return {
        init,
        destroy
    };
};
